<template>
  <h1>Traveller request</h1>
  <div class="trips">
    <TravellerRequestItem v-for="(item, index) in items" :item="item" :key="index" />
  </div>
</template>

<script>
import TravellerRequestItem from './TravellerRequestItem.vue';
export default {
  name: 'TravellerRequest',
  layout: {
    name: 'seeker',
  },
  components: { TravellerRequestItem },
  data: function () {
    return {
      items: [],
    };
  },
  async created() {
    let self = this;
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = this.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    await fetch(apiUrl + 'seeker/traveler-request', {
      method: 'GET',
      headers: headersObject,
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }
        self.$data.items = resData.items;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/content/trip_list';
</style>
